import vrt from '../assets/img/game_SI/1/dan1.jpg'
import kuhinja from '../assets/img/game_SI/1/dan1a.jpg'
import vrataVrt from '../assets/img/game_SI/vrt-vrata.png'
import outItem1 from '../assets/img/game_SI/1/vrt/p1.png'
import outItem2 from '../assets/img/game_SI/1/vrt/p2.png'
import outItem3 from '../assets/img/game_SI/1/vrt/p3.png'
import outItem4 from '../assets/img/game_SI/1/vrt/p4.png'
import outItem5 from '../assets/img/game_SI/1/vrt/p5.png'
import p1 from '../assets/img/game_SI/1/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game_SI/1/kuhinja/172006729 Vanilijev sladkor 6_1 NOVO 6x8g 1.png'
import k2 from '../assets/img/game_SI/1/kuhinja/puding.jpg'
import k3 from '../assets/img/game_SI/1/kuhinja/5907707059445-Tekoca-barva-za-jedila-rdeca-30ml.png'
import k4 from '../assets/img/game_SI/1/kuhinja/4044935018705-Zeko kalup na zatvaranje-4.png'
import k5 from '../assets/img/game_SI/1/kuhinja/r1.png'
import vrataKuhinja from '../assets/img/game_SI/kuhinja-vrata.png'
/*import inItem1 from '../assets/img/game_SI/1/kuhinja/k1.jpeg'
import inItem2 from '../assets/img/game_SI/1/kuhinja/k2.png'
import inItem3 from '../assets/img/game_SI/1/kuhinja/k3.png'
import inItem4 from '../assets/img/game_SI/1/kuhinja/k4.jpeg'
import inItem5 from '../assets/img/game_SI/1/kuhinja/k5.png'*/

const day_1 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-23.5deg", pitch: "-24.1deg" },
                size: { width: 24, height: 29 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "46deg", pitch: "-12deg" },
                size: { width: 16, height: 23 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "138.9deg", pitch: "-52.8deg" },
                size: { width: 29, height: 22 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "234.6deg", pitch: "-20deg" },
                size: { width: 20, height: 29 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-121deg", pitch: "-46.5deg" },
                size: { width: 18, height: 24 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "352.6deg", pitch: "-13.2deg" },
                size: { width: 28, height: 45 },
                product_title: 'Vanilin sladkor',
                product_description: 'Dr. Oetker Vanilin sladkor že od davnega leta 1894 nudi enako kvaliteto. Več kot 100 let se z vanilin sladkorjem bogatijo arome raznih peciv in kolačev, nadevov, desertov, sadnih solat in napitkov.',
                product_image: k1,
                product_url: 'https://oetker-shop.si/izdelek/vanilin-sladkor-6x8g-2/'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "-20.8deg", pitch: "-33deg" },
                size: { width: 70, height: 120 },
                product_title: 'Creative puding slana karamela',
                product_description: 'Dr. Oetker Creative puding slana karamela je bogatega kremastega okusa karamele in je popolna sladica za vse ljubitelje sladkega pa tudi slanega.',
                product_image: k2,
                product_url: 'https://oetker-shop.si/izdelek/creative-puding-slana-karamela/'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-52.5deg", pitch: "-24.8deg" },
                size: { width: 28, height: 95 },
                product_title: 'Tekoča barva za jedila rdeča 30ml',
                product_description: 'Dr. Oetker tekoče jedilne barve so zaradi izjemne intenzivnosti še posebej primerne za barvanje biskvita, prelivov, sladkorja v prahu, fondanta in testa, tisti najpogumnejši pa jih lahko uporabijo v koktajlih.',
                product_image: k3,
                product_url: 'https://oetker-shop.si/izdelek/tekoca-barva-za-jedila-rdeca-30ml/'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "164.7deg", pitch: "-51deg" },
                size: { width: 214, height: 235 },
                product_title: 'Model za peko zlati, zajček',
                product_description: 'Pravi klasik za veliko noč! Z tem Dr. Oetkerjevim modelčkom zlate barve spečite pecivo v obliki sladkega zajčka, nelepljivi premaz pa vam bo omogočil lahko odstranjevanje iz modela.',
                product_image: k4,
                product_url: 'https://oetker-shop.si/izdelek/model-za-peko-zlati-zajcek/'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "293.2deg", pitch: "-28deg" },
                size: { width: 180, height: 110 },
                product_title: 'Jajčka iz granole',
                product_description: 'Priprava teh jajc iz granole je hitra in enostavna, popolna za zajtrk ali malico.',
                product_image: k5,
                product_url: 'https://slo.oetker-recepti.com/jajcka-iz-granole/'
            },
        ]
    },
]

export default day_1