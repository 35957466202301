import vrt from '../assets/img/game_SI/5/d5.jpg'
import kuhinja from '../assets/img/game_SI/5/k5.jpg'
import vrataVrt from '../assets/img/game_SI/vrt-vrata.png'
import outItem1 from '../assets/img/game_SI/5/vrt/1.png'
import outItem2 from '../assets/img/game_SI/5/vrt/2.png'
import outItem3 from '../assets/img/game_SI/5/vrt/3.png'
import outItem4 from '../assets/img/game_SI/5/vrt/4.png'
import outItem5 from '../assets/img/game_SI/5/vrt/5.png'
import p1 from '../assets/img/game_SI/5/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game_SI/5/kuhinja/5907707051296-Pisano-pismo-76-g.png'
import k2 from '../assets/img/game_SI/5/kuhinja/5907707057076-Dekor-mix-zvezdice-10g.png'
import k3 from '../assets/img/game_SI/5/kuhinja/kutija-nostalgija-2.png'
import k4 from '../assets/img/game_SI/5/kuhinja/Posuda za topljenje čokolade-1.png'
import k5 from '../assets/img/game_SI/5/kuhinja/recept10.png'
import vrataKuhinja from '../assets/img/game_SI/kuhinja-vrata.png'

const day_5 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "117.5deg", pitch: "-16deg" },
                size: { width: 20, height: 26 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "22.4deg", pitch: "-15deg" },
                size: { width: 18, height: 24 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-46deg", pitch: "-16.7deg" },
                size: { width: 16, height: 22 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-110.3deg", pitch: "-20.7deg" },
                size: { width: 22, height: 26 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-68deg", pitch: "-14.4deg" },
                size: { width: 20, height: 24 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-68deg", pitch: "-20.8deg" },
                size: { width: 75, height: 92 },
                product_title: 'Pisano pismo 76g',
                product_description: 'S pomočjo sladkorne pisave lahko slaščice okrasite na najrazličnejše načine, samo pustite domišljiji prosto pot! Napišite sporočilo ali pa kaj narišite. V zavitku so 4 tubice z zeleno, rdečo, rumeno in belo barvo.',
                product_image: k1,
                product_url: 'https://oetker-shop.si/izdelek/pisano-pismo-76g/'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "-34.3deg", pitch: "9.5deg" },
                size: { width: 45, height: 80 },
                product_title: 'Dekor mix zvezdice 10g',
                product_description: 'Pisane zvezdice so v beli in 3 pastelnih barvah: rumeni, modri in rdeči. So odlična dekoracija za rojstnodnevno ali praznično torto, ki jo lahko okrasijo tudi otroci.',
                product_image: k2,
                product_url: 'https://oetker-shop.si/izdelek/dekor-mix-zvezdice-10g/'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-102.8deg", pitch: "-32.5deg" },
                size: { width: 255, height: 180 },
                product_title: 'Pločevinasta posoda za piškote “Nostalgija”',
                product_description: 'Zgodovinski motiv iz 1890-ih naredi to pločevinasto posodo zelo posebno. Je bogato okrašena z reliefom na vseh straneh, vključno s pokrovom. Namenjena je shranjevanju domačih piškotov, medenjakov in drugih sladkih dobrot, lahko pa shranite tudi Dr. Oetker pudinge, dekorje, sladke jedi, prelive za torte ali osebne predmete.',
                product_image: k3,
                product_url: 'https://oetker-shop.si/izdelek/plocevinasta-posoda-za-piskote-nostalgija/'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-29.2deg", pitch: "-36deg" },
                size: { width: 120, height: 90 },
                product_title: 'Posoda za taljenje čokolade, nerjaveče jeklo',
                product_description: 'Posoda za taljenje čokolade je izdelana iz visokokakovostnega nerjavečega jekla s posebej dolgim ​​ročajem za lažje rokovanje. Ta kuhinjski pripomoček je idealen za taljenje čokolade in kuverture.',
                product_image: k4,
                product_url: 'https://oetker-shop.si/izdelek/posoda-za-taljenje-cokolade-nerjavece-jeklo/'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-198.2deg", pitch: "-55.5deg" },
                size: { width: 253, height: 250 },
                product_title: 'Korenčkovi muffini z zajčki',
                product_description: 'Če imate radi muffine, morate poskusiti te odlične muffine s korenčkom',
                product_image: k5,
                product_url: 'https://slo.oetker-recepti.com/korenckovi-muffini-z-zajcki/'
            }
        ]
    },
]

export default day_5