import vrt from '../assets/img/game_SI/6/d6.jpg'
import kuhinja from '../assets/img/game_SI/6/k6.jpg'
import vrataVrt from '../assets/img/game_SI/vrt-vrata.png'
import outItem1 from '../assets/img/game_SI/6/vrt/1.png'
import outItem2 from '../assets/img/game_SI/6/vrt/2.png'
import outItem3 from '../assets/img/game_SI/6/vrt/3.png'
import outItem4 from '../assets/img/game_SI/6/vrt/4.png'
import outItem5 from '../assets/img/game_SI/6/vrt/5.png'
import p1 from '../assets/img/game_SI/6/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game_SI/6/kuhinja/cvet.png'
import k2 from '../assets/img/game_SI/6/kuhinja/pud.jpg'
import k3 from '../assets/img/game_SI/6/kuhinja/Modlice Zečja obitelj-6.png'
import k4 from '../assets/img/game_SI/6/kuhinja/170000818-Pekac-za-biskvit-pravokutni-1.png'
import k5 from '../assets/img/game_SI/6/kuhinja/rec1.png'
import vrataKuhinja from '../assets/img/game_SI/kuhinja-vrata.png'

const day_6 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "60.7deg", pitch: "-19.6deg" },
                size: { width: 24, height: 28 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "87deg", pitch: "-30deg" },
                size: { width: 24, height: 28 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-124deg", pitch: "-18.3deg" },
                size: { width: 19, height: 25 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-79.3deg", pitch: "-27.7deg" },
                size: { width: 19, height: 24 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-133.6deg", pitch: "-48.5deg" },
                size: { width: 25, height: 29 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "15.4deg", pitch: "-18.5deg" },
                size: { width: 45, height: 55 },
                product_title: 'Dekor okrasne cvetlice 3g',
                product_description: 'Dr. Oetker Okrasne cvetlice so idealni okraski za posebne priložnosti! Z njimi lahko okrasite različne torte, kolače, mafine in kreme, sladicam pa dajo poseben čar.',
                product_image: k1,
                product_url: 'https://oetker-shop.si/izdelek/dekor-okrasne-cvetlice-3g/'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "-152.8deg", pitch: "-7.3deg" },
                size: { width: 70, height: 115 },
                product_title: 'Puding malina 3 kos',
                product_description: 'Dr. Oetker Original puding z okusom maline je osvežilnega okusa, pripravljen po klasičnem receptu, ki vas bo takoj osvojil.',
                product_image: k2,
                product_url: 'https://oetker-shop.si/izdelek/puding-malina-3-kos/'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-68deg", pitch: "-30.5deg" },
                size: { width: 120, height: 115 },
                product_title: 'Modelčki za piškote ‘Zajčja družina’ 3 kos',
                product_description: 'To veliko noč boste s pomočjo modelčkov “Zajčja družina” naredili najbolj ustvarjalne piškote za družino in prijatelje!',
                product_image: k3,
                product_url: 'https://oetker-shop.si/izdelek/modelcki-za-piskote-zajcja-druzina/'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-125deg", pitch: "-42.8deg" },
                size: { width: 220, height: 230 },
                product_title: 'Pravokotni pekač za torto 38x25x7cm',
                product_description: 'Rdeče siv pravokoten model za torto z visokim robom 38x25x7 cm. Dobro prevaja toploto, vsebuje neprepustno zaščito in je odporen na temperature v pečici do 230 ° C.',
                product_image: k4,
                product_url: 'https://oetker-shop.si/izdelek/pravokotni-pekac-za-torto-28x25x7cm/'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "166.3deg", pitch: "-55.4deg" },
                size: { width: 250, height: 244 },
                product_title: 'Limonina rulada s kislo smetano',
                product_description: 'Limonina rulada s kislo smetano ponuja osvežujočo kremasto poslastico, ki vas bo osvojila s svojim edinstvenim okusom in preprosto pripravo. Ta rulada je kombinacija sočne limone ter kiselkastih not kisle smetane in ustvarja popolno ravnovesje svežine in kremastega zadovoljstva.',
                product_image: k5,
                product_url: 'https://slo.oetker-recepti.com/limonina-rulada-s-kislo-smetano/'
            },
        ]
    },
]

export default day_6