import vrt from '../assets/img/game_SI/3/d3.jpg'
import kuhinja from '../assets/img/game_SI/3/k3.jpg'
import outItem1 from '../assets/img/game_SI/3/vrt/1.png'
import outItem2 from '../assets/img/game_SI/3/vrt/2.png'
import outItem3 from '../assets/img/game_SI/3/vrt/3.png'
import outItem4 from '../assets/img/game_SI/3/vrt/4.png'
import outItem5 from '../assets/img/game_SI/3/vrt/5.png'
import p1 from '../assets/img/game_SI/3/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game_SI/3/kuhinja/fin1.jpg'
import k2 from '../assets/img/game_SI/3/kuhinja/5997381352147-drOetker-Preljev-za-torte-FIX-crveni-1.png'
import k3 from '../assets/img/game_SI/3/kuhinja/170000614-Pekac-za-klasicno-poticosarkelj-klasicni-22-cm.png'
import k4 from '../assets/img/game_SI/3/kuhinja/Modlice za kekse u obliku jajeta s motivima zeca i tulipana.png'
import k5 from '../assets/img/game_SI/3/kuhinja/recept4.png'

const day_3 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-72.0deg", pitch: "-14.2deg" },
                size: { width: 15, height: 23 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "199.0deg", pitch: "-8deg" },
                size: { width: 21, height: 22 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-106.2deg", pitch: "-39deg" },
                size: { width: 18, height: 22 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "24.3deg", pitch: "-24.4deg" },
                size: { width: 15, height: 23 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-37deg", pitch: "-27.5deg" },
                size: { width: 15, height: 22 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-6.8deg", pitch: "-13.5deg" },
                size: { width: 35, height: 45 },
                product_title: 'Finesse naribana limonina lupinica',
                product_description: 'Dr. Oetker Finesse aroma pomaranče katera je alternativna zamenjava za uporabo naribane pomarančne lupine.  Kolači, peciva in deserti bodo s pomočjo Dr. Oetkerjeve Finesse naribane lupinice postali prava specialiteta!  ',
                product_image: k1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/pripomocki-za-peko/arome/limonina-lupinica-finesse'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "-169deg", pitch: "-6.5deg" },
                size: { width: 45, height: 75 },
                product_title: 'Preliv za torte FIX rdeči 50g',
                product_description: 'Z dodajanjem sadnega soka ali enostavno vode  Dr. Oetker Prelivu za torte, nastane prozoren želirni preliv za vse vrste sadnih tort in peciv. Tako bo vsako sadje na torti ali pecivu ostalo lepega in privlačnega videza. Preliv je enostaven za uporabo, vsakič uspe in je zelo čvrst za rezanje.',
                product_image: k2,
                product_url: 'https://oetker-shop.si/izdelek/preliv-za-torte-fix-rdeci-50g/'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-56deg", pitch: "-21.8deg" },
                size: { width: 150, height: 118 },
                product_title: 'Pekač za klasično potico/šarkelj, klasični 22 cm',
                product_description: 'Specite svojo najljubšo potico ali pa šarkelj v Dr. Oetker pekaču! Dolgotrajen pekač navduši z odlično toplotno prevodnostjo in zagotavlja enakomerno peko, dvojna prevleka proti sprijemanju pa omogoča enostavno odstranjevanje in preprečuje, da bi se testo sprijelo na robove pekača',
                product_image: k3,
                product_url: 'https://oetker-shop.si/izdelek/pekac-za-potico-klasicni-22-cm/'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-28deg", pitch: "-38.3deg" },
                size: { width: 120, height: 115 },
                product_title: 'Modelčki za piškote v obliki jajčka z motivi zajčka in tulipana',
                product_description: 'Vnesite delček spomladi v svoj dom! Letos za veliko noč naredite, s pomočjo modelčkov v obliki jajčka z motivom zajčka in tulipana, najbolj domiselne piškote za družino in prijatelje.',
                product_image: k4,
                product_url: 'https://oetker-shop.si/izdelek/modelcki-za-piskote-v-obliki-jajcka-z-motivi-zajcka-in-tulipana/'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "11deg", pitch: "-42.3deg" },
                size: { width: 219, height: 145 },
                product_title: 'Piškoti v obliki pečenih jajčk',
                product_description: 'Povsem preprosto pripravite velikonočne piškotke iz krhkega testa, ki so polnjeni z marelično marmelado',
                product_image: k5,
                product_url: 'https://slo.oetker-recepti.com/piskotki-v-obliki-jajck/'
            },
        ]
    },
]

export default day_3