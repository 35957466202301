import vrt from '../assets/img/game_SI/4/d4.jpg'
import kuhinja from '../assets/img/game_SI/4/k4.jpg'
import outItem1 from '../assets/img/game_SI/4/vrt/1.png'
import outItem2 from '../assets/img/game_SI/4/vrt/2.png'
import outItem3 from '../assets/img/game_SI/4/vrt/3.png'
import outItem4 from '../assets/img/game_SI/4/vrt/4.png'
import outItem5 from '../assets/img/game_SI/4/vrt/5.png'
import p1 from '../assets/img/game_SI/4/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game_SI/4/kuhinja/prhki.png'
import k2 from '../assets/img/game_SI/4/kuhinja/kvas.jpg'
import k3 from '../assets/img/game_SI/4/kuhinja/drOetker-Mini-Muffins-1.png'
import k4 from '../assets/img/game_SI/4/kuhinja/recept3.png'
import k5 from '../assets/img/game_SI/4/kuhinja/alupi za puding s poklopcem.png'

const day_4 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-50.8deg", pitch: "-6.2deg" },
                size: { width: 15, height: 20 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "28.5deg", pitch: "-26deg" },
                size: { width: 23, height: 25 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-16.8deg", pitch: "-22deg" },
                size: { width: 20, height: 25 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "68deg", pitch: "-38.3deg" },
                size: { width: 20, height: 25 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "53deg", pitch: "-6.8deg" },
                size: { width: 19, height: 24 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-67.5deg", pitch: "-22.3deg" },
                size: { width: 105, height: 140 },
                product_title: 'Krhki piškoti 500g',
                product_description: 'S pomočjo receptov iz babičine kuhinje, ki so priloženi v pakiranju, lahko tradicionalne kekse iz krhkega testa hitro in preprosto pripravite na 5 različnih načinov.',
                product_image: k1,
                product_url: 'https://oetker-shop.si/izdelek/krhki-piskoti-500g/'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "15.6deg", pitch: "-19deg" },
                size: { width: 40, height: 50 },
                product_title: 'Kvas instant 4/1',
                product_description: 'Dr. Oetker Kvas je idealen suhi kvas za pripravo sladkih in slanih vrst vzhajanega testa.',
                product_image: k2,
                product_url: 'https://oetker-shop.si/izdelek/kvas-instant-4-1/'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "255.2deg", pitch: "-23.5deg" },
                size: { width: 105, height: 132 },
                product_title: 'Mini muffini 250g',
                product_description: 'Tako očarljivo čokoladni in sočni in tako praktični in simpatični. Idealna mešanica za vse ki ljubijo sladke male zalogajčke. In kot pri ostalih Dr. Oetker Muffinih je tudi priprava Mini Muffinov enostavna in hitra.',
                product_image: k3,
                product_url: 'https://oetker-shop.si/izdelek/mini-muffini-250g/'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-15.8deg", pitch: "-34.5deg" },
                size: { width: 125, height: 105 },
                product_title: 'Muffini s korenčki iz marcipana',
                product_description: 'Naredite preproste in sladke muffine z ročno izdelanimi okrasnimi korenčki',
                product_image: k4,
                product_url: 'https://slo.oetker-recepti.com/muffini-s-korencki-iz-marcipana/'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-40.8deg", pitch: "-34deg" },
                size: { width: 142, height: 100 },
                product_title: 'Modeli za puding s pokrovom 6x150ml',
                product_description: ' Vašo najljubšo sladico – puding, ki ste ga pripravili sami – od zdaj naprej lahko pripravite na še bolj eleganten in kreativen način! Set 6 malih modelčkov je odličen za serviranje Dr. Oetker pudingov in ostalih sladic. ',
                product_image: k5,
                product_url: 'https://oetker-shop.si/izdelek/modeli-za-puding-s-pokrovom-6x150ml/'
            },
        ]
    },
]

export default day_4