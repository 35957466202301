import vrt from '../assets/img/game_SI/2/d2.jpg'
import kuhinja from '../assets/img/game_SI/2/k2.jpg'
import outItem1 from '../assets/img/game_SI/2/vrt/1.png'
import outItem2 from '../assets/img/game_SI/2/vrt/2.png'
import outItem3 from '../assets/img/game_SI/2/vrt/3.png'
import outItem4 from '../assets/img/game_SI/2/vrt/4.png'
import outItem5 from '../assets/img/game_SI/2/vrt/5.png'
import p1 from '../assets/img/game_SI/2/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game_SI/2/kuhinja/bour.jpg'
import k2 from '../assets/img/game_SI/2/kuhinja/Aroma_mandelj_2411-1.png'
import k3 from '../assets/img/game_SI/2/kuhinja/Modlice za kekse u obliku zeca i mrkve.png'
import k4 from '../assets/img/game_SI/2/kuhinja/posipač za brašno-1.png'
import k5 from '../assets/img/game_SI/2/kuhinja/recept8.png'

const day_2 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "216.8deg", pitch: "-22.7deg" },
                size: { width: 20, height: 24 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "-42.5deg", pitch: "-10.3deg" },
                size: { width: 16, height: 21 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-68.2deg", pitch: "-14.2deg" },
                size: { width: 17, height: 22 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "146.3deg", pitch: "-41deg" },
                size: { width: 17, height: 22 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "38deg", pitch: "-9.2deg" },
                size: { width: 19, height: 23 },
                product_title: 'Barve za jajca',
                product_description: 'S pomočjo Dr. Oetker barve za jajca izdelajte najlepše in najbolj živopisne pisanice za vašo velikonočno mizo ali košaro.',
                product_image: p1,
                product_url: 'https://www.oetker.si/si-si/nasi-izdelki/dekorji/Barve%20za%20jajca'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-32.8deg", pitch: "-8.2deg" },
                size: { width: 65, height: 72 },
                product_title: 'Bourbon vanilin sladkor',
                product_description: 'Dr. Oetker Bourbon vanilin sladkor daje nežni vonj in fini okus prave bourbon vanilije smetani, finim testom in nadevom za kolače.',
                product_image: k1,
                product_url: 'https://oetker-shop.si/izdelek/bourbon-vanilijev-sladkor-10g/'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "231.2deg", pitch: "-34deg" },
                size: { width: 32, height: 60 },
                product_title: 'Tekoča aroma za živila – mandelj',
                product_description: 'Dr. Oetker tekoča aroma sladkega mandlja je nepogrešljiva pri aromatiziranju biskvitov, krem, napitkov, sladoleda in drugih sladic.',
                product_image: k2,
                product_url: 'https://oetker-shop.si/izdelek/aroma-mandelj-38-ml/'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "172.5deg", pitch: "-51.5deg" },
                size: { width: 120, height: 115 },
                product_title: 'Modelčki za piškote v obliki zajčka in korenčka',
                product_description: 'S prečudovitim setom modelčkov v obliki zajčka in korenčka v trenutku lahko pričarate izvrstne velikonočne piškote.',
                product_image: k3,
                product_url: 'https://oetker-shop.si/izdelek/modelcki-za-piskote-v-obliki-zajcka-in-korencka/'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-15.8deg", pitch: "-32.2deg" },
                size: { width: 95, height: 95 },
                product_title: 'Sito za moko 350g, pocinkano',
                product_description: 'Enoročni posipalec za moko s premerom približno 10 cm navdušuje z enostavno uporabo! Preprosto vsujte želeno suho sestavino in z eno roko presejte vsebino.',
                product_image: k4,
                product_url: 'https://oetker-shop.si/izdelek/sito-za-moko-350g-2/'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "12deg", pitch: "-41.3deg" },
                size: { width: 200, height: 270 },
                product_title: 'Torta v obliki zajca iz fondanta',
                product_description: 'Uživajte v izdelavi te domiselne torte s pomočjo preprostih korakov, ki vam bodo omogočili, da ustvarite sladko in nepozabno poslastico, ki bo zagotovo pritegnila pozornost in privabila nasmeh vseh okoli vas',
                product_image: k5,
                product_url: 'https://slo.oetker-recepti.com/torta-v-obliki-zajca-iz-fondanta/'
            },
        ]
    },
]

export default day_2